<template>
    <InlineSvg
        :src="`/assets/svgs/${name}.svg`"
        :class="class"
    />
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
    components: { InlineSvg },
    props: {
        name: {
            type: String,
            require: true,
            value: ''
        },
        class: {
            type: String,
            require: false,
            value: ''
        }
    },
};
</script>
