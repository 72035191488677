<template>
    <div class="">
        <carousel :items-to-show="1" v-model="currentSlide">
            <!-- Slides -->
            <slide v-for="slide in testimonials" :key="slide.i" class="flex flex-row justify-start items-start flex-nowrap bg-star-light/50 rounded-lg shadow-lg p-8">
                <!-- Open quote -->
                <div class="h-full quotes">
                    <Icon name="quotes" class="h-12 fill-moon-dark" />
                </div>

                <!-- Quote -->
                <figure>
                    <blockquote v-html="slide.quote" class="h3 text-moon-medium"></blockquote>
                    <figcaption v-html="slide.cite" class="pt-10 subheading text-moon-dark"></figcaption>
                </figure>

                <!-- Close quote -->
                <div class="h-full quotes">
                    <Icon name="quotes" class="rotate-180 h-12 fill-moon-dark" />
                </div>
            </slide>

            <!-- Navigation and Pagination -->
            <template #addons>
                <input type="number" min="0" :max="testimonials.length" v-model="currentSlide" class="h-0 w-0" />

                <!-- Navigation -->
                <div class="flex flex-row justify-between items-center py-2">
                    <button @click="prev">
                        <Icon name="arrow" class="rotate-180 fill-moon-dark h-8 w-8 hover:fill-moon-medium" />
                    </button>

                    <!-- Pagination -->
                    <div class="flex flex-row justify-center items-center gap-4">
                        <button
                            v-for="(slide, index) in testimonials"
                            :class="[
                                index == currentSlide ? 'bg-moon-dark' : 'bg-moon-light',
                                'h-8 w-8 rounded-full hover:bg-moon-medium active:bg-moon-medium cursor-pointer shadow-sm transition-all duration-300'
                            ]"
                            @click="currentSlide = index"
                        >
                            
                        </button>
                    </div>

                    <button @click="next">
                        <Icon name="arrow" class="fill-moon-dark h-8 w-8 hover:fill-moon-medium" />
                    </button>
                </div>
            </template>
        </carousel>
    </div>
</template>

<script>
    // First-party components
    import Icon from './Icon.vue';
    // Vue 3 Carousel: ismail9k
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

    export default {
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            Icon
        },
        props: {
            testimonials: {
                type: Array,
                require: true,
                default: []
            }
        },
        methods: {
            next() {
                let nextSlide = this.currentSlide == this.slidesCount ? this.currentSlide : this.currentSlide + 1;
                this.currentSlide = nextSlide;
            },
            prev() {
                let prevSlide = this.currentSlide == 0 ? this.currentSlide : this.currentSlide - 1;
                this.currentSlide = prevSlide;
            }
        },
        data() {
            return {
                currentSlide: 0,
                slidesCount: this.testimonials.length
            }
        }
    }
</script>
