// Get the global css
import '/src/css/app.pcss';

// Import any third-party scripts
import 'lazysizes';
import { createApp } from 'vue/dist/vue.esm-bundler.js';

// Import any firt-party components
import TestimonialsCarousel from './components/TestimonialsCarousel.vue';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

// Delay init of lazyloading plugin
const lazySizesConfig = lazySizes.cfg;
lazySizesConfig.init = false;

// Create and init any Testimonials components
const testimonialsCarousel = createApp({
    components: { TestimonialsCarousel },
    mounted() { lazySizes.init() }
});
testimonialsCarousel.mount('#testimonials-carousel');